<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.lsptraza.consultarLSP.title')"
			:modelName="modelName"
			:dialogName="lsptrazaConsultarLSPModal"
			:widthDialog="900"
			disableCancel
			:okText="$t('modal.lsptraza.consultarlsp.oktext')"
			:onShow="onShowConsultarLSPModal"
		>
			<template slot="message" slot-scope="data">
				<v-form action ref="consultarLSPForm" @submit.prevent>
					<v-layout row wrap>
						<v-flex xs12 md2>
							<pui-text-field
								:id="`recinto-lsptraza`"
								v-model="data.modalData.recinto"
								:label="$t('lsptraza.modal.recinto')"
								required
								toplabel
								maxlength="4"
								:key="refreshSelect"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12 md8>
							<pui-text-field
								:id="`contenedormatricula-lsptraza`"
								v-model="data.modalData.contenedormatricula"
								:label="$t('lsptraza.modal.contenedormatricula')"
								required
								toplabel
								maxlength="30"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md2>
							<v-btn color="primary" @click="consultarLSP(data.modalData)" depressed class="elevation-0" style="margin-top:23px">
								{{ $t('lsptraza.modal.consultar') }}
							</v-btn>
						</v-flex>
					</v-layout>
					<v-layout row wrap v-if="showRespAEAT" :class="claseColorAEAT">
						<v-flex xs10 md2>
							<pui-text-field
								:id="`respcodaeat-lsptraza`"
								v-model="data.modalData.respcodaeat"
								:label="$t('lsptraza.modal.respcodaeat')"
								disabled
								toplabel
								maxlength="2"
								:key="refreshSelect"
							></pui-text-field>
						</v-flex>
						<v-flex xs2 md1 v-if="false">
							<v-img max-height="60" max-width="60" src="../../assets/images/gateiconUp.png"></v-img>
						</v-flex>
						<v-flex xs2 md1 v-if="false">
							<v-img max-height="60" max-width="60" src="../../assets/images/gateiconDown.png"></v-img>
						</v-flex>
						<v-flex xs2 md1 v-if="showImgUpAEAT">
							<v-img max-height="60" max-width="60" src="../../assets/images/BarreraUp.png"></v-img>
						</v-flex>
						<v-flex xs2 md1 v-if="!showImgUpAEAT">
							<v-img max-height="60" max-width="60" src="../../assets/images/BarreraDown.png"></v-img>
						</v-flex>
						<v-flex xs12 md9>
							<pui-text-area
								:id="`respdescaeat-lsptraza`"
								v-model="data.modalData.respdescaeat"
								:label="$t('lsptraza.modal.respdescaeat')"
								disabled
								toplabel
								maxlength="300"
								rows="1"
								:key="refreshSelect"
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout row wrap v-if="showRespCiudad" :class="claseColorCiudad">
						<v-flex xs10 md2>
							<pui-text-field
								:id="`respcodciudad-lsptraza`"
								v-model="data.modalData.respcodciudad"
								:label="$t('lsptraza.modal.respcodciudad')"
								disabled
								toplabel
								maxlength="2"
								:key="refreshSelect"
							></pui-text-field>
						</v-flex>
						<v-flex xs2 md1 v-if="showImgUpCiudad">
							<v-img max-height="60" max-width="60" src="../../assets/images/BarreraUp.png"></v-img>
						</v-flex>
						<v-flex xs2 md1 v-if="!showImgUpCiudad">
							<v-img max-height="60" max-width="60" src="../../assets/images/BarreraDown.png"></v-img>
						</v-flex>
						<v-flex xs12 md9>
							<pui-text-area
								:id="`respdescciudad-lsptraza`"
								v-model="data.modalData.respdescciudad"
								:label="$t('lsptraza.modal.respdescciudad')"
								disabled
								toplabel
								maxlength="300"
								rows="1"
								:key="refreshSelect"
							></pui-text-area>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.lsptraza.actualizarLSP.title')"
			:modelName="modelName"
			:dialogName="lsptrazaActualizarLSPModal"
			:widthDialog="850"
			disableCancel
			:okText="$t('modal.lsptraza.actualizarlsp.oktext')"
			:onShow="onShowActualizarLSPModal"
		>
			<template slot="message" slot-scope="data">
				<v-form action ref="actualizarLSPForm" @submit.prevent>
					<v-layout row wrap>
						<v-flex xs12 md2>
							<pui-text-field
								:id="`recinto-lsptraza`"
								v-model="data.modalData.recinto"
								:label="$t('lsptraza.modal.recinto')"
								required
								toplabel
								maxlength="4"
								:key="refreshSelect"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12 md5>
							<pui-text-field
								:id="`contenedormatricula-lsptraza`"
								v-model="data.modalData.contenedormatricula"
								:label="$t('lsptraza.modal.contenedormatricula')"
								required
								toplabel
								maxlength="30"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md5>
							<pui-text-field
								:id="`matricula-lsptraza`"
								v-model="data.modalData.matricula"
								:label="$t('lsptraza.modal.matricula')"
								required
								toplabel
								maxlength="30"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md2>
							<v-btn color="primary" @click="actualizarLSP(data.modalData)" depressed class="elevation-0" style="margin-top:23px">
								{{ $t('lsptraza.modal.actualizar') }}
							</v-btn>
						</v-flex>
					</v-layout>
					<v-layout row wrap v-if="showRespActAEAT">
						<v-flex xs10 md2>
							<pui-text-field
								:id="`respcodaeat-lsptraza`"
								v-model="data.modalData.respcodaeat"
								:label="$t('lsptraza.modal.respcodaeat')"
								disabled
								toplabel
								maxlength="2"
								:key="refreshSelect"
							></pui-text-field>
						</v-flex>
						<v-flex xs2 md1 v-if="showImgActUpAEAT">
							<v-img max-height="60" max-width="60" src="../../assets/images/BarreraUp.png"></v-img>
						</v-flex>
						<v-flex xs2 md1 v-if="!showImgActUpAEAT">
							<v-img max-height="60" max-width="60" src="../../assets/images/BarreraDown.png"></v-img>
						</v-flex>
						<v-flex xs12 md9>
							<pui-text-area
								:id="`respdescaeat-lsptraza`"
								v-model="data.modalData.respdescaeat"
								:label="$t('lsptraza.modal.respdescaeat')"
								disabled
								toplabel
								maxlength="300"
								rows="1"
								:key="refreshSelect"
							></pui-text-area>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'lsptraza-modals',
	data() {
		return {
			refreshSelect: 0,
			lsptrazaConsultarLSPModal: 'lsptrazaConsultarLSPAction',
			lsptrazaActualizarLSPModal: 'lsptrazaActualizarLSPAction',
			showRespAEAT: false,
			showRespCiudad: false,
			showRespActAEAT: false,
			claseColorAEAT: '',
			claseColorCiudad: '',
			showImgUpAEAT: false,
			showImgUpCiudad: false,
			showImgActUpAEAT: false
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		consultarLSP(modalData) {
			if (modalData.contenedormatricula == null || modalData.contenedormatricula == '') {
				this.$refs.consultarLSPForm.validate();
				return;
			}
			modalData.respuesta = '';
			this.refreshSelect++;
			const params = {
				recinto: modalData.recinto,
				contenedorMatricula: modalData.contenedormatricula
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.consultaLSP;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.lsptraza.consultarLSP.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					// Valid response, do code and close modal
					//modalData.recinto = response.data.recinto;
					//modalData.contenedormatricula = response.data.contenedormatricula;
					modalData.respcodaeat = response.data.respcodaeat;
					modalData.respdescaeat = response.data.respdescaeat;
					modalData.indpositivoaeat = response.data.indpositivoaeat;
					modalData.respcodciudad = response.data.respcodciudad;
					modalData.respdescciudad = response.data.respdescciudad;
					modalData.indpositivociudad = response.data.indpositivociudad;
					this.showRespAEAT = modalData.respcodaeat != null && modalData.respcodaeat != '';
					this.showRespCiudad = modalData.respcodciudad != null && modalData.respcodciudad != '';
					//this.claseColorAEAT = modalData.indpositivoaeat === '1' ? 'success' : 'error';
					//this.claseColorCiudad = modalData.indpositivociudad === '1' ? 'success' : 'error';
					this.showImgUpAEAT = modalData.indpositivoaeat === '1';
					this.showImgUpCiudad = modalData.indpositivociudad === '1';

					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		actualizarLSP(modalData) {
			if (modalData.contenedormatricula == null || modalData.contenedormatricula == '') {
				this.$refs.consultarLSPForm.validate();
				return;
			}
			modalData.respuesta = '';
			this.refreshSelect++;
			const params = {
				recinto: modalData.recinto,
				contenedorMatricula: modalData.contenedormatricula,
				matricula: modalData.matricula
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.actualizaLSP;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.lsptraza.actualizarLSP.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					// Valid response, do code and close modal
					//modalData.recinto = response.data.recinto;
					//modalData.contenedormatricula = response.data.contenedormatricula;
					//modalData.matricula = response.data.matricula;
					modalData.respcodaeat = response.data.respcodaeat;
					modalData.respdescaeat = response.data.respdescaeat;
					modalData.indpositivoaeat = response.data.indpositivoaeat;
					this.showRespActAEAT = modalData.respcodaeat != null && modalData.respcodaeat != '';
					this.showImgActUpAEAT = modalData.indpositivoaeat === '1';
					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		//onOkConsultarLSPModal(modalData) {
		//	console.log(modalData);
		//},
		onShowConsultarLSPModal(modalData) {
			this.showRespAEAT = false;
			this.showRespCiudad = false;
			this.loadRecintoLSPModal(modalData);
		},
		onShowActualizarLSPModal(modalData) {
			this.showRespActAEAT = false;
			this.loadRecintoLSPModal(modalData);
		},
		loadRecintoLSPModal(modalData) {
			const url = this.$store.getters.getModelByName(this.modelName).url.getRecintoPorDefecto;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.lsptraza.loadRecintoLSP.title');
			this.$puiRequests.getRequest(
				url,
				null,
				response => {
					modalData.recinto = response.data;
					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		}
	}
};
</script>
