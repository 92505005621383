<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs" :navigableDetail="false" readOnly></pui-datatable>
		<lsptraza-modals :modelName="modelName"></lsptraza-modals>
	</div>
</template>

<script>
import lsptrazaActions from './LsptrazaActions';
import lsptrazaModals from './LsptrazaModals.vue';

export default {
	name: 'lsptraza-grid',
	components: {
		'lsptraza-modals': lsptrazaModals
	},
	data() {
		return {
			modelName: 'lsptraza',
			actions: lsptrazaActions.gridactions,
			modelColumnDefs: {}
		};
	},
	mounted() {
		//console.log('M');
		this.loadAction(this.$router.currentRoute);
	},
	beforeRouteUpdate(to, from, next) {
		//console.log('B1');
		next();
		//console.log('B2');
		this.loadAction(to);
		//console.log('B3');
	},
	beforeRouteEnter(to, from, next) {
		//console.log('E1');
		next();
		//this.loadAction(to);
		//console.log('E2');
	},
	beforeRouteLeave(to, from, next) {
		//console.log('L1');
		next();
		//console.log('L2');
		this.loadAction(to);
		//console.log('L3');
	},
	methods: {
		loadAction(to) {
			//console.log('A');
			//const currentRouter = this.$router.currentRoute.path;
			const currentRouter = to.path;
			//console.log(currentRouter);
			if (currentRouter === '/lsptraza_consulta') {
				//console.log(currentRouter);
				//ejecuto la accion de consulta para abrir tcon y modal a la vez
				const model = { name: this.modelName };
				this.actions[0].runAction(this, model);
				this.$router.push({ path: '/'.concat('lsptraza_consulta1') });
			} else if (currentRouter === '/lsptraza_actualizar') {
				//console.log(currentRouter);
				//ejecuto la accion de consulta para abrir tcon y modal a la vez
				const model = { name: this.modelName };
				this.actions[1].runAction(this, model);
				this.$router.push({ path: '/'.concat('lsptraza_actualizar1') });
			}
		}
	}
};
</script>
