/*const sampleAction = {
	id: 'idSampleAction',
	selectionType: 'single', // [single|multiple|general]
	label: 'action.lsptraza.sampleAction',
	functionality: null, // set the functionality if needed
	showInForm: true,
	checkAvailability: function (vue, registries) {
		// Validation to execute action
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-lsptrazaSampleAction-' + model.name + '-show', row);
	}
};*/

const consultarLSPAction = {
	id: 'idConsultarLSPAction',
	selectionType: 'general', // [single|multiple|general]
	label: 'action.lsptraza.consultarLSPAction',
	functionality: null, // set the functionality if needed
	showInForm: true,
	checkAvailability: function(/*vue, registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function(action, model) {
		// Sample code to open a model dialog
		const row = { matricula: null };
		// Get PK for the header
		const objectPk = {};
		row.headerPk = objectPk;
		row.isAction = true;
		if (this.$puiEvents) this.$puiEvents.$emit('pui-modalDialogForm-lsptrazaConsultarLSPAction-' + model.name + '-show', row);
		else action.$puiEvents.$emit('pui-modalDialogForm-lsptrazaConsultarLSPAction-' + model.name + '-show', row);
	}
};

const actualizarLSPAction = {
	id: 'idActualizarLSPAction',
	selectionType: 'general', // [single|multiple|general]
	label: 'action.lsptraza.actualizarLSPAction',
	functionality: 'WRITE_LSPTRAZA', // set the functionality if needed
	showInForm: true,
	checkAvailability: function(/*vue, registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function(action, model) {
		// Sample code to open a model dialog
		const row = { matricula: null };
		// Get PK for the header
		const objectPk = {};
		row.headerPk = objectPk;
		row.isAction = true;
		if (this.$puiEvents) this.$puiEvents.$emit('pui-modalDialogForm-lsptrazaActualizarLSPAction-' + model.name + '-show', row);
		else action.$puiEvents.$emit('pui-modalDialogForm-lsptrazaActualizarLSPAction-' + model.name + '-show', row);
	}
};
export default {
	gridactions: [consultarLSPAction, actualizarLSPAction],
	formactions: []
};
